import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import useOnScreen from 'hooks/custom/useOnScreen';
import cx from 'utils/classnames';
import { trackSectionView } from 'utils/trackingFunctions';
import { capitalizeFirstLetter } from 'utils/stringHelpers';

export default function PageSection({
  children,
  className,
  sectionName,
  gaTrackingTags,
}) {
  const ref = useRef();

  const { hasBeenSeen, isOnScreen } = useOnScreen(ref, '20px');

  useEffect(() => {
    if (hasBeenSeen) {
      trackSectionView(sectionName, gaTrackingTags);
    }
  }, [hasBeenSeen]);

  return (
    <section
      ref={ref}
      className={cx(className, {
        isActive: isOnScreen,
        [`${className}--${sectionName}`]: !!sectionName,
        [`${className}--${sectionName}--${children?.props?.variation}`]:
          !!children?.props?.variation,
        [`${className}--${sectionName}--media${capitalizeFirstLetter(
          children?.props?.imageAlignment
        )}`]: !!children?.props?.imageAlignment,
      })}
    >
      {children}
    </section>
  );
}

PageSection.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string.isRequired,
  sectionName: PropTypes.string.isRequired,
  gaTrackingTags: PropTypes.string.isRequired,
};
