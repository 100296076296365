/* eslint-disable react/no-array-index-key */
import React, { useContext } from 'react';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import getComponent from 'components/COMPONENT_MAP';
import GlobalContext from 'hooks/contexts/GlobalContext';
import { NOP } from 'utils/generalUtils';
import PageSection from './PageSection';

function PageSectionLayout(props) {
  const pageContext = useContext(GlobalContext);
  const { pageSections, isLanding = false } = props;

  if (!pageSections) {
    return null;
  }

  return (
    <div className="pageSectionLayout">
      {pageSections.map(({ component, ...sectionProps }, index) => {
        const SectionComponent = getComponent(component);
        const staticProps = get(
          SectionComponent,
          'staticProps',
          NOP
        )(pageContext, { isLanding });

        return (
          <PageSection
            key={`${component}_${index}`}
            sectionName={component}
            gaTrackingTags={`page-section-slot=${index + 1}`}
            className="pageSectionLayout__section"
          >
            <SectionComponent {...sectionProps} {...staticProps} />
          </PageSection>
        );
      })}
    </div>
  );
}

PageSectionLayout.propTypes = {
  pageSections: PropTypes.arrayOf(
    PropTypes.shape({
      component: PropTypes.string.isRequired,
    })
  ),
  isLanding: PropTypes.bool,
};

export default PageSectionLayout;
