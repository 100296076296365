/**
 * @summary LANDING PAGES: This is any landing page for a microsite, https://domain.com/{slug}/landing.
 * This points to wordpress Landing Pages content types
 */
import React from 'react';
import PageContainer from 'components/global/PageContainer';
import PageSectionLayout from 'components/global/PageSectionLayout';
import { pagePropsType } from 'utils/propTypes';
import getLandingPageData from 'app-requests/content-type-loaders/landing-page-props';
import { clickPortalRedirect } from 'consts';
import { isClickPortal } from 'utils/generalUtils';
import handleServerRedirect from 'utils/handleServerRedirect';

const LandingPage = (props) => {
  const {
    pageProps: { pageSections, ogImg, ...globalPageProps },
  } = props;

  return (
    <PageContainer pageProps={{ ...globalPageProps, ogImg }}>
      <PageSectionLayout pageSections={pageSections} isLanding />
    </PageContainer>
  );
};

/**
 * @summary this is the Nextjs function that will get the server side props.
 * this will call all needed API to create the Landing Page
 */
export async function getServerSideProps(context) {
  // We only need to do this here because this is the main landing page that everything else would get redirected to.
  if (isClickPortal(context.req)) {
    handleServerRedirect(clickPortalRedirect, context);
    return { props: {} };
  }

  const pageProps = await getLandingPageData(context);
  return { props: pageProps };
}

LandingPage.propTypes = {
  pageProps: pagePropsType.isRequired,
};

export default LandingPage;
